import React     from 'react';
import PropTypes from 'prop-types';
import Flux      from '../../flux/Flux';

class ActorPlate extends React.PureComponent {
	render() {
		const languages = this.props.showSpokenLanguages
			? this.props.spokenLanguages.map(l => <span key={"lang-" + l.toLowerCase()} className={"badge-language -lang-" + l.toLowerCase()} />)
			: null;

		return (
			<div className="context-box--media-box__plate -actor-plate-small">
				<ul className="h-list--horizontal">
					<li className="context-box--media-box__list__item actor-name -size-sm">
						{React.createElement(
						this.props.headlineType ? this.props.headlineType : 'h3',
						{className: 'actor-name -size-sm'},
							this.props.name,
						)}
					</li>
					{/* Don't show age on the Video Stars page by tablet-mode. Because if name to large is, number of videos will move down*/}
					{!this.props.hideAge ? <li className="context-box--media-box__list__item actor-age -size-sm">({this.props.age})</li> : ''}
					<li className="context-box--media-box__list__item -right">{languages}</li>
					{this.props.totalVideosCount > 0 &&
					<li className={"context-box--media-box__list__item -videos -right"}>{this.props.totalVideosCount + ' Videos'}</li>
					}
				</ul>
			</div>
		);
	}
}

ActorPlate.propTypes = {
	name:                PropTypes.string.isRequired,
	age:                 PropTypes.number.isRequired,
	showSpokenLanguages: PropTypes.bool,
	spokenLanguages:     PropTypes.array.isRequired,
	totalVideosCount:    PropTypes.number,
	hideAge:             PropTypes.bool,
	headlineType:     	 PropTypes.oneOf(Object.values(Flux.Constants.Headlines)),
};

ActorPlate.defaultProps = {
	showSpokenLanguages: true,
	totalVideosCount:    0,
};

export default ActorPlate;
